<template>
  <b-card
    no-body
  >
    <b-card-header
      :class="{'collapsed': !visible}"
      :aria-expanded="visible ? 'true' : 'false'"
      :aria-controls="collapseItemID"
      role="tab"
      data-toggle="collapse"
    >
      <slot name="header" />
      <i-collapse-item-card-actions-container
        :is-collapsed="!visible"
        :disabled-button-collapse="disabledButtonCollapse"
        :disabled-button-edit="disabledButtonEdit"
        :disabled-button-deletee="disabledButtonDeletee"
        @collapse-actions="updateVisible(!visible)"
        @edit-actions="$emit('edit', elementId)"
        @delete-actions="$emit('delete', elementId)"
      />
    </b-card-header>

    <b-collapse
      :id="collapseItemID"
      v-model="visible"
      :accordion="accordion"
      role="tabpanel"
      :appear="false"
    >
      <b-card-body>
        <slot />
      </b-card-body>
    </b-collapse>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BCollapse,
} from 'bootstrap-vue'
import { v4 as uuidv4 } from 'uuid'
import ICollapseItemCardActionsContainer from '@/components/i-collapse-item-card/ICollapseItemCardActionsContainer.vue'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCollapse,
    ICollapseItemCardActionsContainer,
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    // eslint-disable-next-line vue/require-prop-types
    elementId: {
      default: null,
    },
    disabledButtonCollapse: {
      type: Boolean,
      requried: false,
    },
    disabledButtonEdit: {
      type: Boolean,
      requried: false,
    },
    disabledButtonDeletee: {
      type: Boolean,
      requried: false,
    },
  },
  data() {
    return {
      visible: false,
      collapseItemID: '',
      openOnHover: this.$parent.hover,
    }
  },
  computed: {
    accordion() {
      return this.$parent.accordion ? `accordion-${this.$parent.collapseID}` : null
    },
  },
  created() {
    this.collapseItemID = uuidv4()
    this.visible = this.isVisible
  },
  methods: {
    updateVisible(val = true) {
      this.visible = val
      this.$emit('visible', val)
    },
    collapseOpen() {
      if (this.openOnHover) this.updateVisible(true)
    },
    collapseClose() {
      if (this.openOnHover) this.updateVisible(false)
    },
  },
}
</script>

<style>
#faq-help .card-header {
  padding: 1rem
}
#faq-help .card-header:after {
  background-image: none
}
</style>
