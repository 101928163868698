<template>
  <div>
    <b-form>
      <b-row>
        <b-col
          cols="12"
          md="8"
        >
          <b-card
            no-body
            class="border mb-0"
          >
            <b-card-header class="pb-1">
              <b-card-title>
                <feather-icon
                  icon="Edit2Icon"
                  size="16"
                  class="mr-0 mr-sm-50"
                />
                <span class="d-none d-sm-inline">Елемент</span>
              </b-card-title>
            </b-card-header>

            <b-card-body>
              <b-row>
                <b-col cols="12">
                  <div class="d-flex align-items-left justify-content-left mt-1 pb-50">
                    <b-form-checkbox
                      id="priceSwitch"
                      v-model="contentData.active"
                      :value="1"
                      :unchecked-value="0"
                      :disabled="!ability.can('update', 'ContentHelp')"
                      switch
                    >
                      <span class="switch-icon-left">
                        <feather-icon icon="EyeIcon" />
                      </span>
                      <span class="switch-icon-right">
                        <feather-icon icon="EyeOffIcon" />
                      </span>

                    </b-form-checkbox>
                    Активность
                  </div>
                </b-col>

                <b-col cols="12">
                  <b-form-group
                    label="Внешний код"
                    label-for="input-xmlId"
                  >
                    <b-form-input
                      id="input-xmlId"
                      v-model="contentData.xmlId"
                      :disabled="!ability.can('update', 'ContentHelp')"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label="Сортировка"
                    label-for="input-sort"
                  >
                    <b-form-input
                      id="input-sort"
                      v-model="contentData.sort"
                      :disabled="!ability.can('update', 'ContentHelp')"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label="Наименование"
                    label-for="input-title"
                  >
                    <b-form-input
                      id="input-title"
                      v-model="contentData.title"
                      :disabled="!ability.can('update', 'ContentHelp')"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label="Псевдоним"
                    label-for="input-alias"
                  >
                    <b-form-input
                      id="input-alias"
                      v-model="contentData.alias"
                      :disabled="!ability.can('update', 'ContentHelp')"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label="Текст"
                    label-for="blog-detailText"
                  >
                    <quill-editor
                      id="blog-detailText"
                      v-model="contentData.detailText"
                      :options="snowOption"
                      :disabled="!ability.can('update', 'ContentHelp')"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>

        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <b-row>
            <b-col>
              <b-card-actions
                title="Информация"
                title-icon="InfoIcon"
                action-collapse
                collapsed
                no-body
                class="border mb-1"
              >
                <b-card-body>
                  <b-list-group>
                    <b-list-group-item class="d-flex justify-content-between align-items-center">

                      <h5 class="mb-0">
                        Просмотры
                      </h5>
                      <b-badge
                        variant="primary"
                        pill
                        class="badge-round"
                      >
                        {{ contentData.showCounter }}
                      </b-badge>
                    </b-list-group-item>
                    <b-list-group-item class="flex-column align-items-start">
                      <div class="d-flex w-100 justify-content-between">
                        <h5>
                          Дата первого просмотра
                        </h5>
                      </div>
                      <b-card-text>
                        <span v-if="contentData.showCounterStart">{{ formatDate(contentData.showCounterStart) }} </span>
                        <span v-else>нет</span>
                      </b-card-text>
                    </b-list-group-item>
                    <b-list-group-item class="flex-column align-items-start">
                      <div class="d-flex w-100 justify-content-between">
                        <h5>
                          Создал
                        </h5>
                      </div>
                      <b-card-text>
                        {{ contentData.createdUser }}
                      </b-card-text>
                    </b-list-group-item>
                    <b-list-group-item class="flex-column align-items-start">
                      <div class="d-flex w-100 justify-content-between">
                        <h5>
                          Дата создания
                        </h5>
                      </div>
                      <b-card-text>
                        <span v-if="contentData.createdAt">{{ formatDate(contentData.createdAt) }} </span>
                        <span v-else>нет</span>
                      </b-card-text>
                    </b-list-group-item>
                    <b-list-group-item class="flex-column align-items-start">
                      <div class="d-flex w-100 justify-content-between">
                        <h5>
                          Изменил
                        </h5>
                      </div>
                      <b-card-text>
                        {{ contentData.updatedUser }}
                      </b-card-text>
                    </b-list-group-item>
                    <b-list-group-item class="flex-column align-items-start">
                      <div class="d-flex w-100 justify-content-between">
                        <h5>
                          Дата изменения
                        </h5>
                      </div>
                      <b-card-text>
                        <span v-if="contentData.updatedAt">{{ formatDate(contentData.updatedAt) }} </span>
                        <span v-else>нет</span>
                      </b-card-text>
                    </b-list-group-item>
                  </b-list-group>
                </b-card-body>
              </b-card-actions>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-card
                no-body
                class="border mb-0"
              >
                <b-card-header class="pb-1">
                  <b-card-title>
                    <feather-icon
                      icon="DribbbleIcon"
                      size="16"
                      class="mr-0 mr-sm-50"
                    />
                    <span class="d-none d-sm-inline">SEO</span>
                  </b-card-title>

                </b-card-header>

                <b-card-body>
                  <b-row>
                    <b-col cols="12">
                      <b-form-group
                        label="META TITLE"
                        label-for="input-metaTitle"
                      >
                        <b-form-input
                          id="input-metaTitle"
                          v-model="contentData.metaTitle"
                          :disabled="!ability.can('update', 'ContentHelp')"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12">
                      <b-form-group
                        label="META KEYWORDS"
                        label-for="input-metaKeywords"
                      >
                        <b-form-input
                          id="input-metaKeywords"
                          v-model="contentData.metaKeywords"
                          :disabled="!ability.can('update', 'ContentHelp')"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12">
                      <b-form-group
                        label="META DESCRIPTION"
                        label-for="input-metaDescription"
                      >
                        <b-form-input
                          id="input-metaDescription"
                          v-model="contentData.metaDescription"
                          :disabled="!ability.can('update', 'ContentHelp')"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-card-body>
              </b-card>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import {
  BFormGroup, BFormInput, BForm, BRow, BCol, BCard, BCardTitle, BCardHeader, BCardBody, BCardText, BFormCheckbox, BListGroup, BListGroupItem, BBadge,
} from 'bootstrap-vue'
import { quillEditor } from 'vue-quill-editor'
import ability from '@/libs/acl/ability'
import BCardActions from '@/components/b-card-actions/BCardActions.vue'
import { formatDate } from '@/libs/helper'

export default {
  components: {
    BFormGroup,
    BFormInput,
    BForm,
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BCardHeader,
    BCardBody,
    BCardText,
    BFormCheckbox,
    BListGroup,
    BListGroupItem,
    BBadge,
    quillEditor,
    BCardActions,
  },
  props: {
    contentData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      snowOption: {
        theme: 'snow',
      },
    }
  },
  setup() {
    return {
      formatDate,
      ability,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/quill.scss';
</style>
