import { ref } from '@vue/composition-api'
import contentHelpRepository from '@/repository/contentHelpRepository'
import store from '@/store'
import router from '@/router'
import ability from '@/libs/acl/ability'

export default function contentHelpViewTabFaqElement(faqElementId, emit) {
  const blankData = {
    contentHelpId: router.currentRoute.params.id,
    active: 1,
    sort: 500,
    title: null,
    text: null,
  }

  const elementData = ref({})

  const getElement = id => new Promise(resolve => {
    if (id) {
      contentHelpRepository.getElementFaq(id)
        .then(res => {
          if (!res) {
            throw new Error('Статьи с текущем идентификатором не найдено')
          }
          elementData.value = {
            ...blankData,
            ...res,
            createdUser: !res.createdUser ? '' : `${res.createdUser.firstName || ''} ${res.createdUser.lastName}` || '',
            updatedUser: !res.updatedUser ? '' : `${res.updatedUser.firstName || ''} ${res.updatedUser.lastName}` || '',
          }
          resolve()
        })
    } else {
      elementData.value = JSON.parse(JSON.stringify(blankData))
    }
  })

  const resetData = () => {
    if (!faqElementId) {
      elementData.value = JSON.parse(JSON.stringify(blankData))
    }
  }

  const creatParams = () => {
    const param = new FormData()
    Object.keys(elementData.value).forEach(key => {
      param.append(key, elementData.value[key])
    })
    return param
  }

  const createElement = () => new Promise(resolve => {
    if (ability.can('update', 'ContentHelp')) {
      const param = creatParams()
      contentHelpRepository.createElementFaq(param)
        .then(() => {
        // emit('refetch-data')
          emit('update:is-edit-element-faq-sidebar-active', false)
          resolve(true)
        })
        .catch(error => {
          store.commit('app/SET_ERROR', error)
        })
    } else {
      store.commit('app/SET_ERROR', 'Недостаточно прав для создания')
    }
  })

  const updateElement = id => new Promise(resolve => {
    if (ability.can('update', 'ContentHelp')) {
      const param = creatParams()
      contentHelpRepository.updateElementFaq(id, param)
        .then(() => {
        // emit('refetch-data')
          emit('update:is-edit-element-faq-sidebar-active', false)
          resolve(true)
        })
        .catch(error => {
          store.commit('app/SET_ERROR', error)
        })
    } else {
      store.commit('app/SET_ERROR', 'Недостаточно прав для изменения')
    }
  })

  const deleteElement = id => new Promise(resolve => {
    if (ability.can('delete', 'ContentHelp')) {
      contentHelpRepository.deleteElementFaq(id)
        .then(() => {
          resolve(true)
        })
        .catch(error => {
          store.commit('app/SET_ERROR', error)
        })
    } else {
      store.commit('app/SET_ERROR', 'Недостаточно прав для удаления')
    }
  })

  return {
    getElement,
    elementData,
    resetData,
    createElement,
    updateElement,
    deleteElement,
  }
}
