<template>
  <div>
    <!-- Alert: No item found -->
    <b-alert
      v-if="errorData"
      variant="danger"
      :show="userData === undefined"
    >
      <h4 class="alert-heading">
        Ошибка
      </h4>
      <div class="alert-body">
        {{ errorData }}. Перейти в
        <b-link
          class="alert-link"
          :to="{ name: 'content-help'}"
        >
          список статей
        </b-link>
      </div>
    </b-alert>

    <b-card
      v-else
      :key="contentId"
      no-body
    >
      <b-card-header>
        <div class="d-flex align-items-center">
          <feather-icon
            size="24"
            class="mr-1"
            icon="EditIcon"
          />
          <b-card-title
            v-if="contentId === 'new'"
            class="ml-25"
          >
            Создание новой статьи
          </b-card-title>
          <b-card-title
            v-else
            class="ml-25"
          >
            <span v-if="(contentData && contentData.title)">{{ contentData.title }} - </span>
            <span v-else>статья id={{ contentId }} - </span>

            <span v-if="ability.can('update', 'ContentHelp')">изменение</span>
            <span v-else>просмотр</span>
          </b-card-title>
        </div>
        <div>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="primary"
            :disabled="(tabs === 1 || !ability.can('update', 'ContentHelp'))"
            @click="save()"
          >
            <span class="text-nowrap">Сохранить</span>
          </b-button>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            class="ml-1"
            variant="outline-primary"
            @click="close()"
          >
            <span class="text-nowrap">Закрыть</span>
          </b-button>
        </div>
      </b-card-header>
      <b-card-body>
        <b-tabs v-model="tabs">
          <b-tab active>
            <template #title>
              <feather-icon
                icon="FileTextIcon"
                size="16"
                class="mr-0 mr-sm-50"
              />
              <span class="d-none d-sm-inline">Основное</span>
            </template>
            <content-help-view-tab-general
              :content-data="contentData"
              class="mt-2 pt-75"
            />
          </b-tab>
          <b-tab :disabled="(!contentId || contentId == 'new')">
            <template #title>
              <feather-icon
                icon="HelpCircleIcon"
                size="16"
                class="mr-0 mr-sm-50"
              />
              <span class="d-none d-sm-inline">Вопрос-ответ</span>
              <span class="d-none d-sm-inline"> ({{ (contentData.faq || []).length }})</span>
            </template>
            <content-help-view-tab-faq
              :element-list="contentData.faq"
              class="mt-2 pt-75"
              @refetch-data="getElement()"
            />
          </b-tab>
        </b-tabs>
      </b-card-body>

    </b-card>
  </div>
</template>

<script>
import router from '@/router'
import { ref } from '@vue/composition-api'

import {
  BAlert, BLink, BTab, BTabs, BCard, BCardHeader, BCardBody, BCardTitle, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ability from '@/libs/acl/ability'
import contentHelpView from './contentHelpView'
import ContentHelpViewTabGeneral from './ContentHelpViewTabGeneral.vue'
import ContentHelpViewTabFaq from './ContentHelpViewTabFaq.vue'

export default {
  components: {
    BAlert,
    BLink,
    BTab,
    BTabs,
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BButton,

    ContentHelpViewTabGeneral,
    ContentHelpViewTabFaq,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      tabs: 0,
    }
  },
  methods: {
    async save() {
      const res = await this.saveElement(this.contentId)
      if (this.contentId !== res.id) {
        this.contentId = res.id
        this.$router.replace({ name: 'content-help-view', params: { id: res.id } })
      }
      this.getElement()
      this.$swal({
        icon: 'success',
        title: 'Данные успешно сохранены!',
        showConfirmButton: false,
        timer: 1500,
        customClass: {
          confirmButton: 'btn btn-success',
        },
        buttonsStyling: false,
      })
    },
    close() {
      this.$router.go(-1)
    },
  },
  setup() {
    const contentId = ref(router.currentRoute.params.id)
    const {
      contentData,
      errorData,

      saveElement,
      resetData,
      getElement,
    } = contentHelpView()

    return {
      contentId,

      contentData,
      errorData,

      saveElement,
      resetData,
      getElement,

      ability,
    }
  },
}
</script>

<style scoped>
  .card-header{padding-bottom: 1rem;}
</style>
