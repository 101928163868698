import { ref } from '@vue/composition-api'
import contentHelpRepository from '@/repository/contentHelpRepository'
import store from '@/store'
import router from '@/router'
import ability from '@/libs/acl/ability'

export default function contentHelpView() {
  const blankData = {
    xmlId: null,
    active: 1,
    activeTo: null,
    activeFrom: null,
    sort: null,
    title: null,
    alias: null,
    detailText: null,
    searchableContent: null,
    showCounter: 0,
    showCounterStart: null,
    metaTitle: null,
    metaKeywords: null,
    metaDescription: null,
    createdUser: '',
    updatedUser: '',
    createdAt: null,
    updatedAt: null,
    faq: [],
  }

  const errorData = ref(null)
  const contentData = ref({})

  const getElement = () => new Promise(resolve => {
    contentHelpRepository.getElement(router.currentRoute.params.id)
      .then(res => {
        if (!res) {
          throw new Error('Статьи с текущем идентификатором не найдено')
        }
        contentData.value = {
          ...blankData,
          ...res,
          createdUser: !res.createdUser ? '' : `${res.createdUser.firstName || ''} ${res.createdUser.lastName}` || '',
          updatedUser: !res.updatedUser ? '' : `${res.updatedUser.firstName || ''} ${res.updatedUser.lastName}` || '',
        }
        resolve()
      })
      .catch(error => {
        errorData.value = error ? `${error}`.replace('Error: ', '') : ''
      })
  })

  if (router.currentRoute.params.id && router.currentRoute.params.id === 'new') {
    contentData.value = JSON.parse(JSON.stringify(blankData))
  } else if (router.currentRoute.params.id && router.currentRoute.params.id !== 'new' && !Number.isNaN(parseInt(router.currentRoute.params.id, 10))) {
    getElement()
  } else {
    errorData.value = 'Не корректный идентификатор страницы'
  }

  const creatParams = () => {
    const {
      xmlId, active, activeTo, activeFrom, sort, title, alias, detailText, searchableContent, showCounter, showCounterStart, metaTitle, metaKeywords, metaDescription,
    } = contentData.value
    const fields = {
      xmlId, active, activeTo, activeFrom, sort, title, alias, detailText, searchableContent, showCounter, showCounterStart, metaTitle, metaKeywords, metaDescription,
    }

    const param = new FormData()
    Object.keys(fields).forEach(key => {
      param.append(key, fields[key])
    })
    return param
  }

  const saveElement = id => new Promise(resolve => {
    if (ability.can('update', 'ContentHelp')) {
      const param = creatParams()
      if (id && String(id) === 'new') {
        contentHelpRepository.createElement(param)
          .then(res => {
          // необходимо реплейснуть
            resolve(res)
          })
          .catch(error => {
            store.commit('app/SET_ERROR', error)
          })
      } else if (id && !String(id) !== 'new' && !Number.isNaN(parseInt(id, 10))) {
        contentHelpRepository.updateElement(id, param)
          .then(res => {
            resolve(res)
          })
          .catch(error => {
            store.commit('app/SET_ERROR', error)
          })
      } else {
        store.commit('app/SET_ERROR', 'Некоректный идентификатор страницы')
      }
    } else {
      store.commit('app/SET_ERROR', 'Недостаточно прав для изменения/создания')
    }
  })

  return {
    contentData,
    errorData,

    saveElement,
    getElement,
  }
}
