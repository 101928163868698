<template>
  <div>
    <content-help-view-tab-faq-element-edit
      :is-edit-element-faq-sidebar-active.sync="isEditElementFaqSidebarActive"
      :faq-element-id="faqElementId"
      @refetch-data="refetchData"
    />
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      class="mr-2"
      :disabled="!ability.can('update', 'ContentHelp')"
      @click="createElementFaq"
    >
      Добавить вопрос
    </b-button>

    <app-collapse
      id="faq-help"
      accordion
      type="margin"
      class="mt-2"
    >

      <i-collapse-item-card
        v-for="( item, index) in elementList"
        :key="index"
        :element-id="item.id"
        :disabled-button-deletee="!ability.can('update', 'ContentHelp')"
        @edit="editElementFaq"
        @delete="deleteElementFaq"
      >
        <template #header>
          <div>
            <feather-icon
              v-if="item.active == 1"
              class="text-success mr-1"
              icon="EyeIcon"
              size="16"
            />
            <feather-icon
              v-else
              class="text-danger mr-1"
              icon="EyeOffIcon"
              size="16"
            />
            <b-badge class="mr-1">
              id {{ item.id }}
            </b-badge>
            <span class="mr-1">{{ item.sort }}</span>
            <span class="lead collapse-title">{{ item.title }}</span>
          </div>
        </template>
        {{ item.text }}
      </i-collapse-item-card>

    </app-collapse>
  </div>

</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BButton, BBadge,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import ICollapseItemCard from '@/components/i-collapse-item-card/ICollapseItemCard.vue'
import ability from '@/libs/acl/ability'
import ContentHelpViewTabFaqElementEdit from './ContentHelpViewTabFaqElementEdit.vue'
import contentHelpViewTabFaqElementEdit from './contentHelpViewTabFaqElement'

export default {
  components: {
    BButton,
    BBadge,
    AppCollapse,
    ContentHelpViewTabFaqElementEdit,
    ICollapseItemCard,
  },
  directives: {
    Ripple,
  },
  props: {
    elementList: {
      type: Array,
      default: () => ([]),
    },
  },
  methods: {
    createElementFaq() {
      this.faqElementId = null
      this.isEditElementFaqSidebarActive = true
    },
    editElementFaq(id) {
      this.faqElementId = id
      this.isEditElementFaqSidebarActive = true
    },
    async deleteElementFaq(id) {
      const result = await this.$swal({
        title: 'Подтверждение удаления!',
        text: 'Вы уверены в необходимости удалить вопрос?',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Отмена',
        confirmButtonText: 'Удалить',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
      if (result.value) {
        this.deleteElement(id)
          .then(() => {
            this.refetchData()
            this.$swal({
              icon: 'success',
              title: 'Элемент успешно удален!',
              showConfirmButton: false,
              timer: 1500,
              customClass: {
                confirmButton: 'btn btn-success',
              },
              buttonsStyling: false,
            })
          })
      }
    },
  },
  setup(props, { emit }) {
    const isEditElementFaqSidebarActive = ref(false)
    const faqElementId = ref(null)

    const {
      deleteElement,
      resetData,
    } = contentHelpViewTabFaqElementEdit()

    const refetchData = () => {
      emit('refetch-data')
    }

    return {
      isEditElementFaqSidebarActive,
      faqElementId,
      resetData,

      refetchData,
      deleteElement,

      ability,
    }
  },
}
</script>
