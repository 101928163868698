import conect from './api'

const { axiosIns } = conect()

export default {
  async elementList(params = {}) {
    const { error, result } = (await axiosIns.get('/content/help', { params })).data
    if (error) throw error

    return result
  },
  async getElement(id) {
    const { error, result } = (await axiosIns.get(`/content/help/${id}`)).data
    if (error) throw error

    return result
  },
  async createElement(...args) {
    const { error, result } = (await axiosIns.post('/content/help/', ...args)).data
    if (error) throw error

    return result
  },
  async updateElement(id, ...args) {
    const { error, result } = (await axiosIns.put(`/content/help/${id}`, ...args)).data
    if (error) throw error

    return result
  },
  async deleteElement(id) {
    const { error, result } = (await axiosIns.delete(`/content/help/${id}`)).data
    if (error) throw error

    return result
  },
  async getElementFaq(id) {
    const { error, result } = (await axiosIns.get(`/content/help-faq/${id}`)).data
    if (error) throw error

    return result
  },
  async createElementFaq(...args) {
    const { error, result } = (await axiosIns.post('/content/help-faq/', ...args)).data
    if (error) throw error

    return result
  },
  async updateElementFaq(id, ...args) {
    const { error, result } = (await axiosIns.put(`/content/help-faq/${id}`, ...args)).data
    if (error) throw error

    return result
  },
  async deleteElementFaq(id) {
    const { error, result } = (await axiosIns.delete(`/content/help-faq/${id}`)).data
    if (error) throw error

    return result
  },
}
