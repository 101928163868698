<template>
  <div class="heading-elements">
    <div class="inline-button mb-0">
      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        variant="flat-secondary"
        class="btn-icon"
        :disabled="disabledButtonDeletee"
        @click="$emit('delete-actions')"
      >
        <feather-icon icon="XIcon" />
      </b-button>
      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        variant="flat-secondary"
        class="btn-icon"
        :disabled="disabledButtonEdit"
        @click="$emit('edit-actions')"
      >
        <feather-icon icon="Edit2Icon" />
      </b-button>
      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        variant="flat-secondary"
        class="btn-icon btn-collapse"
        :class="{ rotate: isCollapsed }"
        :disabled="disabledButtonCollapse"
        @click="$emit('collapse-actions')"
      >
        <feather-icon icon="ChevronDownIcon" />
      </b-button>
    </div>
  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BButton,
  },
  directives: {
    Ripple,
  },
  props: {
    isCollapsed: {
      type: Boolean,
      requried: true,
    },
    disabledButtonCollapse: {
      type: Boolean,
      requried: false,
    },
    disabledButtonEdit: {
      type: Boolean,
      requried: false,
    },
    disabledButtonDeletee: {
      type: Boolean,
      requried: false,
    },
  },
}
</script>

<style scoped>
.inline-button {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
}
.card .card-header .heading-elements .btn-collapse.rotate svg {
    transform: rotate(-180deg);
}
.card .card-header .heading-elements .btn-collapse svg {
    transition: all 0.25s ease-out;
}
</style>
