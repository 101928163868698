<template>
  <b-sidebar
    id="edit-element-faq-sidebar"
    :visible="isEditElementFaqSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @shown="getElement(faqElementId)"
    @change="(val) => $emit('update:is-edit-element-faq-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5
          v-if="!faqElementId"
          class="mb-0"
        >
          Добавить
        </h5>
        <h5
          v-if="faqElementId"
          class="mb-0"
        >
          Редактировать
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <div class="d-flex align-items-left justify-content-left mt-1 pb-50">
            <b-form-checkbox
              id="activeFaqSwitch"
              v-model="elementData.active"
              :value="1"
              :unchecked-value="0"
              :disabled="!ability.can('update', 'ContentHelp')"
              switch
            >
              <span class="switch-icon-left">
                <feather-icon icon="EyeIcon" />
              </span>
              <span class="switch-icon-right">
                <feather-icon icon="EyeOffIcon" />
              </span>

            </b-form-checkbox>
            Активность
          </div>

          <b-form-group
            label="Сортировка"
            label-for="faq-sort"
          >
            <b-form-input
              id="faq-sort"
              v-model="elementData.sort"
              :disabled="!ability.can('update', 'ContentHelp')"
              trim
            />
          </b-form-group>

          <validation-provider
            #default="validationContext"
            name=" "
            rules="required"
          >
            <b-form-group
              label="Вопрос"
              label-for="faq-title"
              :state="getValidationState(validationContext)"
            >
              <b-form-input
                id="faq-title"
                v-model="elementData.title"
                :disabled="!ability.can('update', 'ContentHelp')"
                trim
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="  "
            rules="required"
          >
            <b-form-group
              label="Ответ"
              label-for="faq-text"
              :state="getValidationState(validationContext)"
            >
              <quill-editor
                id="faq-text"
                v-model="elementData.text"
                :options="snowOption"
                :disabled="!ability.can('update', 'ContentHelp')"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              :disabled="!ability.can('update', 'ContentHelp')"
            >
              Сохранить
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Отмена
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormCheckbox,
} from 'bootstrap-vue'
// import { ref } from '@vue/composition-api'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import ru from 'vee-validate/dist/locale/ru.json'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
import { quillEditor } from 'vue-quill-editor'
import ability from '@/libs/acl/ability'
import contentHelpViewTabFaqElement from './contentHelpViewTabFaqElement'

localize('ru', ru)

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormCheckbox,

    BButton,

    ValidationProvider,
    ValidationObserver,

    quillEditor,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isEditElementFaqSidebarActive',
    event: 'update:is-edit-element-faq-sidebar-active',
  },
  props: {
    isEditElementFaqSidebarActive: {
      type: Boolean,
      required: true,
    },
    faqElementId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      required,
      snowOption: {
        theme: 'snow',
      },
    }
  },
  methods: {
    async onSubmit() {
      if (this.faqElementId) {
        await this.updateElement(this.faqElementId)
      } else {
        await this.createElement()
      }
      this.$emit('refetch-data')
      this.$swal({
        icon: 'success',
        title: 'Элемент успешно сохранен!',
        showConfirmButton: false,
        timer: 1500,
        customClass: {
          confirmButton: 'btn btn-success',
        },
        buttonsStyling: false,
      })
    },
  },
  setup({ faqElementId }, { emit }) {
    const {
      getElement,
      elementData,
      resetData,
      createElement,
      updateElement,
    } = contentHelpViewTabFaqElement(faqElementId, emit)
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetData)

    return {
      getElement,
      elementData,
      resetData,
      createElement,
      updateElement,

      refFormObserver,
      getValidationState,
      resetForm,

      ability,
    }
  },
}
</script>

<style scoped>

</style>
